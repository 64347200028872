<template>
  <BoardLayout
    :backButton="true"
    routeName="Studio"
    :mainLabel="subcontractorDetails ? subcontractorDetails.name : ''"
    :tabs="tabs"
  ></BoardLayout>
</template>
<script lang="ts">
import { defineComponent, computed, onMounted } from 'vue'
import BoardLayout from '@/components/templates/BoardLayout.vue'
import { useStore } from '@/store'
import { useRoute } from 'vue-router'
import { ActionTypes as studioActions } from '@/store/studio/actions'
export default defineComponent({
  components: {
    BoardLayout
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    store.dispatch(studioActions.GET_SUBCONTRACTOR_DETAILS, +route.params.id)
    const subcontractorDetails = computed(() => store.state.studio.SubcontractorDetails)
    const tabs = [
      { routerName: 'SubcontractorProjects', routerText: 'Projects' },
      { routerName: 'SubcontractorInformation', routerText: 'Details' },
      { routerName: 'SubcontractorDocuments', routerText: 'Documents' }
    ]
    async function getSubcontractor() {
      await store.dispatch(studioActions.GET_SUBCONTRACTOR_DETAILS, +route.params.id)
    }
    onMounted(getSubcontractor)
    return { tabs, subcontractorDetails }
  }
})
</script>
